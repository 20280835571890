import axios from "axios"

export const Api = axios.create({
  baseURL: "https://apremia.com",
    // baseURL: "http://dev.localhost",
  headers: {
    "Content-Type": "multipart/form-data",
  },
})

export const submitMessageRequest = async (payload) => {
    // return await Api.post('https://httpbin.org/delay/3', payload)
    return await Api.post('/es/lead_create', payload)
}

export default Api;
